// CardElements.js
import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column; // Changed from flex-flow to flex-direction for better readability
  align-items: center;

  background: #fff; // Add background to match .cards background in card.css
  width: 90%;
  max-width: 1120px;
  margin: 0 auto;
  margin-top: 50px;

  @media screen and (max-width: 480px) {
  }

  @media screen and (max-width: 375px) {
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  margin: 50px 0 45px;
  position: relative;
`;

export const CardsItems = styled.ul`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column; // Add this to stack items vertically
  justify-content: center;
  list-style: none;
  padding: 0;
  

  @media only screen and (min-width: 1024px) {
    flex-direction: row; // Changed from display: flex; to flex-direction: row to align with card.css behavior
  }
`;

export const CardItem = styled.li`
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`;

export const CardItemLink = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
`;

export const CardItemPicWrap = styled.figure`
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;

  &::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #d3d3d3;
    box-sizing: border-box;
  }
`;

export const CardItemImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;

  &:hover {
    transform: scale(1.1);
  }
`;

export const CardItemInfo = styled.div`
  padding: 20px 30px 30px;
`;

export const CardItemText = styled.h5`
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
`;

export const CardItemSubText = styled.p`
  color: #252e48; // Farbe für den neuen Text
  font-size: 14px; // Kleinere Schriftgröße für den Absatz
  margin-top: 10px; // Abstand nach oben
  line-height: 20px; // Zeilenhöhe
`;

export const CardsHeading = styled.h1`
  text-align: center;
  font-size: 48px;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
  @media screen and (max-width: 393px) {
    font-size: 28px;
  }
  @media screen and (max-width: 360px) {
    font-size: 26px;
  }
`;
