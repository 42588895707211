import React, { useState } from "react";
import ImpressumModal from "./ImpressumModal";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinksContainer,
  SocialIcons,
  SocialIconLink,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

import { FaInstagram } from "react-icons/fa";
import Image from "../../images/Logo.png";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>S. Autopflege</FooterLinkTitle>
              <FooterLink to="home" smooth={true} duration={500}>
                Home
              </FooterLink>
              <FooterLink to="info" smooth={true} duration={500}>
                Info
              </FooterLink>
              <FooterLink to="referenzen" smooth={true} duration={500}>
                Portfolio
              </FooterLink>
              <FooterLink to="kontakt" smooth={true} duration={500}>
                Kontakt
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>
                <FooterLink as="div" onClick={toggleModal}>
                  Impressum
                </FooterLink>
              </FooterLinkTitle>
              <FooterLink>0174 319 6819</FooterLink>
              <FooterLink>Kelvinstr. 33</FooterLink>
              <FooterLink>Halle 2</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="home" smooth={true} duration={500}>
              <img
                src={Image}
                alt="Logo"
                style={{ height: "40px", width: "auto" }}
              />
            </SocialLogo>
            <WebsiteRights>© 2024</WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.instagram.com/schroeders.autopflege/"
                target="_blank"
              >
                <FaInstagram />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
      {isModalOpen && <ImpressumModal closeModal={toggleModal} />}
    </FooterContainer>
  );
};

export default Footer;
