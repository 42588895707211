import React, { useState } from "react";
import Video from "../../images/HeroSectionBg.jpg";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  HeroImage,
} from "./HeroSectionElements";
import Image from "../../images/Logo.png";
import { Button } from "../ButtonElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        {/* Video ist von pexels.com */}
        <VideoBg src={Video} type="image" />
      </HeroBg>
      <HeroContent>
        <HeroImage src={Image} alt="Logo" />
        <HeroH1>Schröders Autopflege</HeroH1>
        <HeroP>Exklusive Pflege für Ihr Fahrzeug</HeroP>
        <HeroBtnWrapper>
          <Button
            to="info"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            Weitere Infos {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
