import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Cards from "../components/References";
import ContactSection from "../components/Contact";
import "../styles.css";
import { homeObjOne, homeObjTwo } from "../components/InfoSection/Data";

const Home = () => {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <Cards />
      {/* <Services /> */}
      <ContactSection {...homeObjTwo} />
      <Footer />
    </>
  );
};

export default Home;
