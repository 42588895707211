import React from "react";
import styled from "styled-components";

const MapWrapper = styled.div`
  width: 100%;
  height: 375px; /* Standardhöhe */

  @media screen and (max-width: 768px) {
    height: 300px; /* Höhe für Tablets */
  }

  @media screen and (max-width: 480px) {
    height: 200px; /* Höhe für Smartphones */
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const AddressMap = () => {
  return (
    <MapWrapper>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1717.9479379259176!2d6.991377!3d50.869895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf215478ce0dad%3A0x9b18b33bd414a483!2sSchr%C3%B6ders%20Autopflege!5e1!3m2!1sde!2sde!4v1731501860571!5m2!1sde!2sde"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    </MapWrapper>
  );
};

export { AddressMap };
