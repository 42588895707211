import React from "react";
const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1717.9479379259176!2d6.991377!3d50.869895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf215478ce0dad%3A0x9b18b33bd414a483!2sSchr%C3%B6ders%20Autopflege!5e1!3m2!1sde!2sde!4v1731501860571!5m2!1sde!2sde"
        width="100%"
        height="375"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </div>
  );
};
export { AddressMap };
