import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";
import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 1000px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 480px) {
    height: 900px;
  }

  @media screen and (max-width: 375px) {
    height: 700px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroImage = styled.img`
  width: 150px; /* Passe die Breite des Bildes an */
  height: auto; /* Automatische Skalierung */
  margin-bottom: 16px; /* Abstand zwischen dem Bild und dem Text */
  @media screen and (max-width: 768px) {
    width: 120px; /* Für kleinere Bildschirme */
  }
  @media screen and (max-width: 480px) {
    width: 100px; /* Noch kleinere Bildschirme */
  }
`;

export const HeroH1 = styled.h1`
  color: #fff;
  text-shadow: 2px 2px 3px #000;
  font-size: 58px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 48px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
  @media screen and (max-width: 393px) {
    font-size: 28px;
  }
  @media screen and (max-width: 360px) {
    font-size: 26px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  text-shadow: 2px 2px 3px #000;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 21px;
  }
  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
  @media screen and (max-width: 393px) {
    font-size: 13px;
  }
  @media screen and (max-width: 360px) {
    font-size: 13px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
