// index.js
import React from "react";
import {
  CardsContainer,
  CardsWrapper,
  CardsItems,
  CardItem,
  CardItemLink,
  CardItemPicWrap,
  CardItemImg,
  CardItemInfo,
  CardItemText,
  CardsHeading,
  CardItemSubText,
} from "./CardElements";
import Image from "../../images/HeroBackground.jpg";
import ImageKeramik from "../../images/GalleryKeramik.jpg";
import ImageKeramik2 from "../../images/GalleryKeramik.jpg";
import ImageLeder from "../../images/GalleryLeder2.jpg";
import ImageInnenraum from "../../images/GalleryInnenraum.jpg";
import ImageHand from "../../images/GalleryHand.jpg";
import ImageLeasing from "../../images/GalleryLeasing2.jpg";

function Cards() {
  return (
    <CardsContainer id="referenzen">
      <CardsHeading>Portfolio</CardsHeading>
      <CardsWrapper>
        <CardsItems>
          <Card
            src={ImageKeramik2}
            text="Brillanter Glanz, dauerhafter Schutz"
            subtext="Unsere erstklassige Keramikversiegelung verleiht Ihrem Fahrzeug nicht nur
            einen beeindruckenden Glanz, sondern bietet auch langanhaltenden Schutz vor 
            Umwelteinflüssen. Die innovative Technologie schützt den Lack vor Schmutz und
            UV-Strahlung, sodass Ihr Fahrzeug dauerhaft wie neu erscheint – mit einem makellosen
            Finish, das höchsten Ansprüchen gerecht wird.
            "
            label="Keramikversiegelung"
          />
          <Card
            src={ImageLeasing}
            text="Perfekte Rückgabe leicht gemacht"
            subtext="Unsere Leasingaufbereitung stellt sicher, dass Ihr Fahrzeug in einwandfreiem Zustand zurückgegeben wird. Wir kümmern uns um Lackkorrekturen, Politur, gründliche Innenraumreinigung und Detailpflege. Für Lackierarbeiten und Felgenreparaturen arbeiten wir mit erfahrenen Partnern zusammen, um Ihnen einen umfassenden Service aus einer Hand zu bieten und unnötige Zusatzkosten bei der Rückgabe zu vermeiden."
            label="Leasingaufbereitung"
          />
        </CardsItems>
        <CardsItems>
          <Card
            src={ImageLeder}
            text="Leder in Perfektion – langlebig und luxuriös"
            subtext="Schützen und pflegen Sie Ihre Lederausstattung mit unserer professionellen Lederaufbereitung.
            Flecken, Risse und Abnutzungen werden gründlich aufbereitet, damit Ihr Leder wieder geschmeidig und
            edel wirkt."
            label="Lederaufbereitung"
          />
          <Card
            src={ImageHand}
            text="Schonende Pflege für höchsten Glanz"
            subtext="Unsere Handwäsche ist die sanfte Alternative zu herkömmlichen Waschstraßen.
            Mit viel Liebe zum Detail und hochwertigen Reinigungsmitteln reinigen wir Ihr Fahrzeug
            gründlich und lackschonend."
            label="Handwäsche"
          />
          <Card
            src={ImageInnenraum}
            text="Frische und Sauberkeit bis ins kleinste Detail"
            subtext="Gönnen Sie Ihrem Fahrzeug eine intensive Innenraumaufbereitung. Wir reinigen und pflegen Sitze, Polster, Leder und Armaturen mit höchster Sorgfalt – selbst schwer zugängliche Ecken. Für einen Innenraum, der frisch, sauber und wie neu wirkt."
            label="Innenraumpflege"
          />
        </CardsItems>
      </CardsWrapper>
    </CardsContainer>
  );
}

const Card = ({ src, text, subtext, label }) => (
  <CardItem>
    <CardItemLink to="#">
      <CardItemPicWrap data-category={label}>
        <CardItemImg src={src} alt="Travel Image" />
      </CardItemPicWrap>
      <CardItemInfo>
        <CardItemText>{text}</CardItemText>
        <CardItemSubText>{subtext}</CardItemSubText>
      </CardItemInfo>
    </CardItemLink>
  </CardItem>
);

export default Cards;
