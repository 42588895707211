import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #ffffff;
  color: #010606;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: left;
  overflow-y: auto;
  max-height: 80vh;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const Text = styled.p`
  margin: 0 0 10px;
  line-height: 1.6;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  background: #dac67c;
  color: #010606;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: #b59c63;
  }
`;

const ImpressumModal = ({ closeModal }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <SectionTitle>Impressum</SectionTitle>
        <p>&nbsp;</p>
        <Text><strong>Christian Schröder</strong></Text>
        <Text>Einzelunternehmer, Fahrzeugpflege</Text>
        <Text>Kelvinstr. 33-02</Text>
        <Text>50996 Köln</Text>

        <SectionTitle>Kontakt</SectionTitle>
        <Text><strong>Telefon:</strong> +49 (0) 174 319 6819</Text>
        <Text><strong>E-Mail:</strong> info@schroeders-autopflege.de</Text>

        <SectionTitle>Umsatzsteuer-ID</SectionTitle>
        <Text>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</Text>
        <Text><strong>Steuer-Nr.: 219/5303/2306</strong></Text>

        <SectionTitle>EU-Streitschlichtung</SectionTitle>
        <Text>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#DAC67C", textDecoration: "underline" }}
          >
            https://ec.europa.eu/consumers/odr/
          </a>.
        </Text>
        <Text>Unsere E-Mail-Adresse finden Sie oben im Impressum.</Text>

        <SectionTitle>Verbraucherstreitbeilegung/Universalschlichtungsstelle</SectionTitle>
        <Text>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </Text>

        <SectionTitle>Zentrale Kontaktstelle nach dem Digital Services Act - DSA</SectionTitle>
        <Text>
          <strong>Verordnung (EU) 2022/265</strong>
        </Text>
        <Text>
          Unsere zentrale Kontaktstelle für Nutzer und Behörden nach Art. 11, 12 DSA erreichen Sie wie folgt:
        </Text>
        <Text><strong>E-Mail:</strong> info@schroeders-autopflege.de</Text>
        <Text><strong>Telefon:</strong> 0174 3196819</Text>
        <Text>Die für den Kontakt zur Verfügung stehenden Sprachen sind: Deutsch, Englisch.</Text>

        <CloseButton onClick={closeModal}>Schließen</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ImpressumModal;
