import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
  SocialIcons,
  SocialIconLink,
} from "./SidebarElements";

import { FaInstagram } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon color="#dac67c" />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="home" onClick={toggle}>
            Home
          </SidebarLink>
          <SidebarLink to="info" onClick={toggle}>
            Info
          </SidebarLink>
          <SidebarLink to="referenzen" onClick={toggle}>
            Portfolio
          </SidebarLink>
          <SidebarLink to="kontakt" onClick={toggle}>
            Kontakt
          </SidebarLink>
        </SidebarMenu>

        <SocialIcons>
        <SocialIconLink
                href="https://www.instagram.com/schroeders.autopflege/"
                target="_blank"
              >
                <FaInstagram />
            </SocialIconLink>
          </SocialIcons>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
